import { CLOSED_RECIPE_URL } from '@/constants';
import { UserState } from 'store/userSlice';
import { RecipeTeaser } from 'types';
import { Recipe } from 'types/Recipe';

export const getRecipeUrl = (recipe: Recipe | RecipeTeaser): string => {
  const urlName = recipe.Url.split('/')[1];
  const routeUrl = recipe.IsFreeVideo
    ? recipe.Url
    : CLOSED_RECIPE_URL + urlName;
  return routeUrl;
};

export const hasAccessToRecipe = (
  recipe: Recipe | RecipeTeaser,
  user: UserState
): boolean => {
  return recipe.IsFreeVideo || user.IsLoggedIn;
};

export const isFavoriteRecipe = (
  recipe: Recipe | RecipeTeaser,
  user: UserState
): boolean => {
  return user.Favorites?.some((f) => f.Id === recipe.Id);
};

export const formatIngredientAmount = (
  defaultAmount: number,
  numberOfServings: number,
  defaultServings: number
) => {
  const amount = defaultAmount * (numberOfServings / defaultServings);

  if (!toLocaleStringSupportsLocales()) {
    return amount;
  }

  return amount
    .toLocaleString('da-DK', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: !1
    })
    .replace(',00', '');
};

function toLocaleStringSupportsLocales() {
  var number = 0;
  try {
    number.toLocaleString('i');
  } catch (e) {
    return e.name === 'RangeError';
  }
  return false;
}
