import {
  ACCOUNT_SIGN_IN_URL,
  PROFILE_CANCEL_MEMBERSHIP_URL,
  PROFILE_CHANGE_CREDIT_CARD_URL,
  PROFILE_CHANGE_MEMBERSHIP_URL,
  PROFILE_CHANGE_PASSWORD_URL,
  PROFILE_DISCOUNT_URL,
  PROFILE_MY_FAVORITES,
  PROFILE_MY_PROFILE,
  PURCHASE_MEMBERSHIP_URL
} from '@/constants';
import { useIsAuthenticated, useSignOut } from '@/hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { DropdownMenu, DropdownMenuItem } from './Dropdown';
import { CloseIcon, RecipeIcon } from './Icons';
import { UserIcon } from './Icons/UserIcon';
import { MenuButton, MenuLink } from './MenuItem';

export default function UserMenu() {
  const user = useSelector((state: RootState) => state.user);
  useIsAuthenticated();

  // Undetermined authentication status
  if (user.IsLoggedIn === null) {
    return null;
  }

  return (
    <div className="lg:ml-auto mr-4 lg:mr-4">
      {user.IsLoggedIn ? (
        <AuthenticatedMenu user={user} />
      ) : (
        <UnauthenticatedMenu />
      )}
    </div>
  );
}

const AuthenticatedMenu = ({ user }) => {
  const signOut = useSignOut();
  const handleSignOutClick = async () => {
    await signOut();
  };

  return (
    <div>
      <DropdownMenu
        openButton={<MenuOpenButton user={user} />}
        closeButton={
          <MenuButton mobileLabel="Luk">
            <CloseIcon />
          </MenuButton>
        }
      >
        <DropdownMenuItem url={PROFILE_MY_FAVORITES}>
          Mine favoritter
        </DropdownMenuItem>
        <DropdownMenuItem url={PROFILE_MY_PROFILE}>Min profil</DropdownMenuItem>
        <DropdownMenuItem url={PROFILE_DISCOUNT_URL}>
          Min rabatkode
        </DropdownMenuItem>
        <DropdownMenuItem url={PROFILE_CHANGE_PASSWORD_URL}>
          Skift kodeord
        </DropdownMenuItem>
        <DropdownMenuItem url={PROFILE_CHANGE_CREDIT_CARD_URL}>
          Skift betalingskort
        </DropdownMenuItem>
        <DropdownMenuItem url={PROFILE_CHANGE_MEMBERSHIP_URL}>
          Skift medlemskab
        </DropdownMenuItem>
        <DropdownMenuItem url={PROFILE_CANCEL_MEMBERSHIP_URL}>
          Opsig medlemskab
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleSignOutClick}>Log ud</DropdownMenuItem>
      </DropdownMenu>
    </div>
  );
};

const UnauthenticatedMenu = () => (
  <ul className="flex flex-row space-x-4 lg:space-x-3">
    <li>
      <MenuLink url={PURCHASE_MEMBERSHIP_URL} mobileLabel="Tilmeld">
        <span className="hidden lg:block">Bliv medlem</span>
        <span className="lg:hidden">
          <RecipeIcon />
        </span>
      </MenuLink>
    </li>
    <li>
      <MenuLink url={ACCOUNT_SIGN_IN_URL} mobileLabel="Log ind">
        <span className="hidden lg:block">Log ind</span>
        <span className="lg:hidden">
          <UserIcon />
        </span>
      </MenuLink>
    </li>
  </ul>
);

const MenuOpenButton = ({ user }) => (
  <MenuButton mobileLabel={user?.IsLoggedIn ? 'Profil' : null}>
    <UserIcon />
    {user.IsLoggedIn && (
      <span className="hidden lg:block lg:ml-2">{user.Name}</span>
    )}
  </MenuButton>
);
