export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const fbTrackPageView = () => {
  fbTrack('PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const fbTrackEvent = (name: string, options: any) => {
  fbTrack(name, options);
};

const fbTrack = (name: string, options = {}) => {
  if (FB_PIXEL_ID && typeof window !== 'undefined') {
    (window as any).fbq('track', name, options);
  }
};
