import { IMAGE_ROOT_URL } from '@/constants';

const CDN_URL = 'https://res.cloudinary.com/vollmer/image/fetch/';

export const getRawImageUrl = (imgSrc: string, params: string = '') => {
  return `${IMAGE_ROOT_URL}${imgSrc}${params}`;
};

export const getCdnImageUrl = (imgSrc: string, params: string = '') => {
  return `${CDN_URL}${params}/${IMAGE_ROOT_URL}${imgSrc}`;
};

export const getShopifyImageUrl = (imgSrc: string, params: string = '') => {
  return `${CDN_URL}${params}/${imgSrc}`;
};
