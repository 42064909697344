const dev = process.env.NODE_ENV !== 'production';

export const ROOT_URL = dev
  ? 'http://localhost:8001'
  : 'https://klubvollmer.dk';

export const API_ROOT_URL = dev
  ? 'http://localhost:8001/Umbraco/Api'
  : 'https://cms.klubvollmer.dk/Umbraco/Api';

export const IMAGE_ROOT_URL = 'https://cms.klubvollmer.dk';

export const ALL_RECIPES_URL = '/opskrifter/';
export const CLOSED_RECIPE_URL = '/closed-recipe?url=';

// Profile
export const ACCOUNT_SIGN_IN_URL = '/profil/log-ind/';
export const ACCOUNT_SIGNED_OUT_URL = '/profil/logget-ud/';
export const ACCOUNT_FORGOT_PASSWORD_URL = '/profil/glemt-kodeord/';

// My profile
export const PROFILE_MY_PROFILE = '/min-profil/';
export const PROFILE_MY_FAVORITES = '/min-profil/favoritter/';
export const PROFILE_DISCOUNT_URL = '/min-profil/rabatkode/';
export const PROFILE_CHANGE_CREDIT_CARD_URL =
  '/min-profil/skift-betalingskort/';
export const PROFILE_CHANGE_PASSWORD_URL = '/min-profil/skift-kodeord/';
export const PROFILE_CHANGE_MEMBERSHIP_URL = '/min-profil/skift-medlemskab/';
export const PROFILE_CANCEL_MEMBERSHIP_URL = '/min-profil/opsig-medlemskab/';
export const CHANGE_PAYMENT_CARD_API_URL =
  API_ROOT_URL + '/ProfileV2/UpdatePaymentMethod/';

export const EVENTS_URL = '/events/';

export const PURCHASE_MEMBERSHIP_URL = '/#signup';
export const PURCHASE_GIFTCARD_URL = '/gavekort/';

export const REDEEM_GIFTCARD_URL = '/gavekort/indloes/';
export const SHOP_URL = 'https://shop.klubvollmer.dk/';

export const FAQ_URL = '/ofte-stillede-spoergsmaal/';
export const COOKING_SCHOOL_URL = '/information/kokkeskole/';
export const TALKS_URL = '/information/foredrag/';
export const CONTACT_URL = '/information/kontakt/';
export const COOKIES_PERSONAL_DATA_URL = '/information/cookies-og-persondata/';
export const TERMS_URL = '/information/handelsbetingelser/';
