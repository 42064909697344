import { API_ROOT_URL } from '@/constants';
import { useMutation } from 'react-query';

interface CommentPost {
  parentId?: number;
  url: string;
  text: string;
}

const saveComment = async (comment: CommentPost) => {
  const response = await fetch(API_ROOT_URL + '/RecipeV2/CreateComment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(comment)
  });
  if (response.status !== 200) {
    throw {
      status: response.status,
      message: response.statusText
    };
  }
  return response;
};

export const useSaveComment = () => {
  return useMutation(saveComment, {
    onSuccess: (data) => {
      console.log(data);
    },
    onError: () => {
      console.error('Error saving comment');
    },
    onSettled: () => {}
  });
};

const deleteComment = async (id: number) => {
  const response = await fetch(
    API_ROOT_URL + '/RecipeV2/DeleteComment/?id=' + id,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  );
  if (response.status !== 200) {
    throw {
      status: response.status,
      message: response.statusText
    };
  }
  return response;
};

export const useDeleteComment = () => useMutation(deleteComment);
