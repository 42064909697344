import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecipeTeaser } from 'types';

export interface UserState {
  AccountNumber: string;
  Name: string;
  MemberKey: string;
  IsLoggedIn?: boolean;
  Favorites: RecipeTeaser[];
}

const initialState: UserState = {
  AccountNumber: null,
  Name: null,
  MemberKey: null,
  IsLoggedIn: null,
  Favorites: []
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAsSignedIn: (state, action: PayloadAction<UserState>) => {
      state.AccountNumber = action.payload.AccountNumber;
      state.Name = action.payload.Name;
      state.MemberKey = action.payload.MemberKey;
      state.IsLoggedIn = true;
      state.Favorites = action.payload.Favorites;
    },
    setAsSignedOut: (state) => {
      state.AccountNumber = null;
      state.Name = null;
      state.MemberKey = null;
      state.IsLoggedIn = false;
      state.Favorites = [];
    },
    setFavorites: (
      state,
      action: PayloadAction<{ Favorites: RecipeTeaser[] }>
    ) => {
      state.Favorites = [...action.payload.Favorites];
    },
    toggleFavorite: (
      state,
      action: PayloadAction<{ Recipe: RecipeTeaser }>
    ) => {
      if (state.Favorites?.find((f) => f.Id === action.payload.Recipe.Id)) {
        state.Favorites = [
          ...state.Favorites.filter((f) => f.Id !== action.payload.Recipe.Id)
        ];
      } else {
        state.Favorites = [...state.Favorites, action.payload.Recipe];
      }
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAsSignedIn, setAsSignedOut, setFavorites, toggleFavorite } =
  userSlice.actions;

export default userSlice.reducer;
