import {
  ACCOUNT_SIGNED_OUT_URL,
  ALL_RECIPES_URL,
  API_ROOT_URL
} from '@/constants';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAsSignedIn, setAsSignedOut } from 'store/userSlice';
import { ResetPassword } from 'types/Auth';
import { fetcher, postRequest } from './fetch-helper';
import * as Sentry from '@sentry/nextjs';

export const useSignIn = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  return async ({ username, password }): Promise<boolean> => {
    const response = await fetch(API_ROOT_URL + '/Auth/SignIn', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        Email: username,
        Password: password
      })
    });

    if (response.ok) {
      const { AccountNumber, Name, MemberKey, Favorites } =
        await response.json();

      dispatch(setAsSignedIn({ AccountNumber, Name, MemberKey, Favorites }));
      Sentry.setUser({ username: AccountNumber });
      router.push(ALL_RECIPES_URL);

      return Promise.resolve(true);
    } else {
      return Promise.reject(true);
    }
  };
};

const getSignOut = async () =>
  fetch(API_ROOT_URL + '/Auth/SignOut', {
    method: 'POST',
    credentials: 'include'
  });

export const useSignOut = () => {
  const router = useRouter();

  return async () => {
    await getSignOut();
    router.push(ACCOUNT_SIGNED_OUT_URL);
  };
};

const getIsAuthenticated = async () =>
  await fetch(API_ROOT_URL + '/Auth/IsAuthenticated', {
    method: 'POST',
    credentials: 'include'
  });

export const useIsAuthenticated = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async function isAuthenticated() {
      const response = await getIsAuthenticated();
      if (response.ok) {
        const { AccountNumber, Name, MemberKey, Favorites } =
          await response.json();
        dispatch(setAsSignedIn({ AccountNumber, Name, MemberKey, Favorites }));
      } else {
        dispatch(setAsSignedOut());
      }
    })();
  }, [dispatch]);
};

export const resetPassword = async (data: ResetPassword) =>
  fetcher('/AuthV2/ResetPassword', postRequest(data));
