export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const gtmTrackPageView = (url: string) => {
  if (GTM_ID && typeof window !== 'undefined') {
    (window as any).dataLayer.push({
      event: 'pageview',
      page: url
    });
  }
};
