import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik';

export const useFormikHelper = () => {
  const { validateForm, setTouched } = useFormikContext();

  const validateAndTouch = async (): Promise<boolean> => {
    const errors = await validateForm();
    const isValid = Object.keys(errors).length === 0;
    if (isValid) {
      return Promise.resolve(true);
    } else {
      setTouched(
        setNestedObjectValues<FormikTouched<any>>(errors, true),
        false
      );
      return Promise.reject(true);
    }
  };

  return { validateAndTouch };
};
