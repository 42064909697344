import Link from 'next/link';
import { useContext } from 'react';
import { DropdownContext } from './DropdownContext';

interface Props {
  url?: string;
  className?: string;
  target?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

export const DropdownMenuItem = ({
  url = '#',
  className = '',
  target,
  onClick,
  children
}: Props) => {
  const { setIsOpen } = useContext(DropdownContext);

  return (
    <li>
      <Link href={url}>
        <a
          className={`
            block w-full py-2 px-4 text-left text-gray-700 hover:bg-gray-100
            ${className}
          `}
          onClick={() => {
            if (onClick) {
              onClick();
            }

            setIsOpen(false);
          }}
          target={target}
        >
          {children}
        </a>
      </Link>
    </li>
  );
};
