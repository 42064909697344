import Link from 'next/link';

interface Props {
  url?: string;
  onClick?: () => void;
  label?: string;
  children: React.ReactNode;
}

const classes = `
  flex
  items-center
  justify-center
  p-1
  h-[38px]
  w-[38px]
  lg:w-auto
  lg:px-3
  font-normal
  text-sm
  mx-auto
  md:text-base
  text-white
  whitespace-nowrap
  bg-primary
  transition
  hover:bg-black/30
  rounded-full
`;

interface MenuLinkProps {
  url: string;
  mobileLabel?: string;
  target?: string;
  children: React.ReactNode;
}

export const MenuLink = ({
  url,
  target = null,
  mobileLabel,
  children
}: MenuLinkProps) => (
  <>
    <Link href={url}>
      <a className={classes} target={target}>
        {children}
      </a>
    </Link>
    <Label>{mobileLabel}</Label>
  </>
);

interface MenuButtonProps {
  onClick?: () => void;
  mobileLabel?: string;
  children: React.ReactNode;
}

export const MenuButton = ({
  onClick,
  mobileLabel,
  children
}: MenuButtonProps) => (
  <>
    <button className={classes} onClick={onClick}>
      {children}
    </button>
    <Label>{mobileLabel}</Label>
  </>
);

const Label = ({ children }: { children: React.ReactNode }) =>
  children ? (
    <span className="mt-1 text-xs text-center text-white block lg:hidden">
      {children}
    </span>
  ) : null;
