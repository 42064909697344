import {
  autocomplete,
  getAlgoliaFacets,
  getAlgoliaResults
} from '@algolia/autocomplete-js';
import { createTagsPlugin, Tag } from '@algolia/autocomplete-plugin-tags';
import algoliasearch from 'algoliasearch';
import { getCdnImageUrl } from 'helpers';
import { useLockScroll } from 'hooks/useLockScroll';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { groupBy } from 'ramda';
import { createElement, Fragment, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { SearchIcon } from './Icons/SearchIcon';
import { MenuButton } from './MenuItem';

const searchClient = algoliasearch(
  'POBJV8RN85',
  '6e9bbea1fb1d10403d01866353e2d710'
);

export default function SearchBar() {
  const user = useSelector((state: RootState) => state.user);
  const router = useRouter();
  const search = useRef(null);
  const searchContainer = useRef(null);
  const { setLockScroll } = useLockScroll();
  //let lastScrollTop = 0;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!searchContainer.current) {
        return undefined;
      }

      const tagsPlugin = createTagsPlugin<any>({
        getTagsSubscribers() {
          return [
            {
              sourceId: 'Categories',
              getTag({ item }) {
                return item;
              }
            },
            {
              sourceId: 'CookingTime',
              getTag({ item }) {
                return item;
              }
            }
          ];
        },
        transformSource({ state }) {
          return undefined;
        }
      });

      search.current = autocomplete({
        container: searchContainer.current,
        placeholder: 'Søg efter opskrift eller råvare',
        openOnFocus: true,
        plugins: [tagsPlugin],
        renderer: { createElement, Fragment },
        initialState: {},
        onSubmit({ state, setIsOpen, setQuery, refresh }) {
          // "Prevent" closing the search bar pressing
          // the enter key or hitting the "Search" button
          // on virtual keyboard on mobile devices.
          setTimeout(() => {
            setIsOpen(true);
            setQuery(state.query);
            refresh();
          });
        },
        translations: {
          detachedCancelButtonText: 'Annuller'
        },
        render({ children }: { children: any }, root) {
          render(children, root);
        },
        onStateChange({ prevState, state }) {
          if (prevState.isOpen !== state.isOpen) {
            setLockScroll(state.isOpen);
          }
        },
        getSources({ query, state }) {
          const tagsByFacet = groupBy<Tag<any>>(
            (tag) => tag.facet,
            state.context.tagsPlugin['tags']
          );

          return [
            {
              sourceId: 'categories',
              onSelect({ item, state, setQuery, refresh }) {
                const query = state.query;
                toggleSelectedTags('Categories', item, tagsPlugin);

                // Workaround for input being cleared onSelect when using detachedMediaQuery
                // https://github.com/algolia/autocomplete/issues/636
                setTimeout(() => {
                  setQuery(query);
                  refresh();
                }, 2);
              },
              getItems({ query }) {
                return getAlgoliaFacets({
                  searchClient,
                  queries: [
                    {
                      indexName: 'recipes',
                      facet: 'Categories',
                      params: {
                        maxFacetHits: 30,
                        sortFacetValuesBy: 'alpha'
                      }
                    }
                  ],
                  transformResponse({ facetHits }) {
                    // Categories needs to have more than a
                    // single recipe in order to be displayed.
                    return facetHits[0]
                      .filter((hit) => hit.count > 1)
                      .map((hit) => ({
                        ...hit,
                        facet: 'Categories'
                      }));
                  }
                });
              },
              templates: {
                header() {
                  return (
                    <h4 className="text-sm font-semibold mt-2">Kategorier</h4>
                  );
                },
                item({ item, components }) {
                  return (
                    <TagButton
                      facet="Categories"
                      tag={item}
                      tags={tagsPlugin.data.tags}
                    />
                  );
                }
              }
            },

            {
              sourceId: 'cookingTime',
              onSelect({ item, state, setQuery, refresh }) {
                const query = state.query;
                toggleSelectedTags('CookingTime', item, tagsPlugin);

                // Workaround for input being cleared onSelect when using detachedMediaQuery
                // https://github.com/algolia/autocomplete/issues/636
                setTimeout(() => {
                  setQuery(query);
                  refresh();
                }, 2);
              },
              getItems({ query }) {
                return getAlgoliaFacets({
                  searchClient,
                  queries: [
                    {
                      indexName: 'recipes',
                      facet: 'CookingTime',
                      params: {
                        maxFacetHits: 30
                      }
                    }
                  ],
                  transformResponse({ results, facetHits }) {
                    const sorted = [
                      '0-15 minutter',
                      '15-30 minutter',
                      '30-60 minutter',
                      '1-2 timer',
                      '2+ timer'
                    ];

                    return facetHits[0]
                      .map((hit) => ({
                        ...hit,
                        facet: 'CookingTime'
                      }))
                      .sort(
                        (a, b) =>
                          sorted.indexOf(a.label) - sorted.indexOf(b.label)
                      );
                  }
                });
              },
              templates: {
                header() {
                  return (
                    <h4 className="text-sm font-semibold mt-2">
                      Tilberedningstid
                    </h4>
                  );
                },
                item({ item, components }) {
                  return (
                    <TagButton
                      facet="CookingTime"
                      tag={item}
                      tags={tagsPlugin.data.tags}
                    />
                  );
                }
              }
            },

            {
              sourceId: 'recipes',
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: 'recipes',
                      query,
                      params: {
                        hitsPerPage:
                          query || tagsPlugin.data.tags.length ? 100 : 5,
                        attributesToSnippet: ['Name:255'],
                        snippetEllipsisText: '…',
                        filters: mapToAlgoliaFilters(tagsByFacet)
                      }
                    }
                  ]
                });
              },
              transformResponse({ hits }) {
                return hits;
              },
              onSelect(event) {
                router.push(event.item.Url.toString());
              },
              templates: {
                header({ items }) {
                  const isSearching = query || tagsPlugin.data.tags.length;
                  const noOfItems = items.length;

                  if (isSearching && noOfItems === 0) {
                    return null;
                  }

                  return (
                    <h4 className="text-sm font-semibold mt-2">
                      {isSearching
                        ? `${noOfItems} ${
                            noOfItems === 1 ? 'opskrift' : 'opskrifter'
                          } matcher din søgning`
                        : `De ${noOfItems} nyeste opskrifter`}
                    </h4>
                  );
                },
                item({ item, components }) {
                  return (
                    <SearchResultItem hit={item} components={components} />
                  );
                },
                noResults() {
                  return (
                    <div className="bg-gray-50 mt-4 py-8 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8 fill-cyan-500 mx-auto"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 576 512"
                      >
                        <path d="M180.5 141.5C219.7 108.5 272.6 80 336 80C399.4 80 452.3 108.5 491.5 141.5C530.5 174.5 558.3 213.1 572.4 241.3C577.2 250.5 577.2 261.5 572.4 270.7C558.3 298 530.5 337.5 491.5 370.5C452.3 403.5 399.4 432 336 432C272.6 432 219.7 403.5 180.5 370.5C164.3 356.7 150 341.9 137.8 327.3L48.12 379.6C35.61 386.9 19.76 384.9 9.474 374.7C-.8133 364.5-2.97 348.7 4.216 336.1L50 256L4.216 175.9C-2.97 163.3-.8133 147.5 9.474 137.3C19.76 127.1 35.61 125.1 48.12 132.4L137.8 184.7C150 170.1 164.3 155.3 180.5 141.5L180.5 141.5zM416 224C398.3 224 384 238.3 384 256C384 273.7 398.3 288 416 288C433.7 288 448 273.7 448 256C448 238.3 433.7 224 416 224z" />
                      </svg>
                      <p className="mt-4 font-semibold">
                        Desværre, ingen opskrifter matcher din søgning.
                      </p>
                    </div>
                  );
                }
              }
            }
          ];
        }
      });

      return () => {
        search.current.destroy();
      };
    }
  }, [search, setLockScroll, router]);

  return (
    <>
      <div
        className={`
          ml-8 mr-6 w-full lg:w-6/12
          hidden lg:block
          ${!user.IsLoggedIn ? '!hidden' : ''}
        `}
        ref={searchContainer}
      ></div>

      {user.IsLoggedIn && (
        <div className="lg:hidden mr-4 md:mr-8 lg:mr-4">
          <MenuButton
            mobileLabel="Søg"
            onClick={() => {
              if (search.current) {
                search.current.setIsOpen(true);
              }
            }}
          >
            <SearchIcon />
          </MenuButton>
        </div>
      )}
    </>
  );
}

const TagButton = ({ tag, tags, facet }) => {
  const isActive = !!tags.find(
    (x) => x.facet === facet && x.label === tag.label
  );

  return (
    <span
      className={`
        py-1 px-2 lg:py-1.5 lg:px-2.5 inline-block mr-2 mb-2 rounded-full text-sm md:text-base
        ${
          isActive
            ? 'bg-primary hover:bg-slate-700 text-white'
            : 'bg-gray-100 hover:bg-gray-200'
        }
        `}
    >
      {tag.label}
    </span>
  );
};

const SearchResultItem = ({ hit, components }) => {
  return (
    <div
      className="inline-block hover:bg-gray-100 bg-white overflow-hidden rounded shadow hover:shadow-md align-center card-zoom"
      title={hit.Name}
    >
      <Image
        className="rounded-t card-zoom-image"
        src={getCdnImageUrl(hit.Image, 'w_555,h_312,c_fill')}
        alt={hit.Name}
        width={555}
        height={312}
        unoptimized
      />

      <h3 className="font-semibold text-gray-800 text-sm py-1 px-2 truncate">
        <components.Highlight hit={hit} attribute="Name" />
      </h3>
    </div>
  );
};

function mapToAlgoliaFilters(
  tagsByFacet: Record<string, Array<Tag<any>>>,
  operator = 'AND'
) {
  return Object.keys(tagsByFacet)
    .map((facet) => {
      return `(${tagsByFacet[facet]
        .map(({ label }) => `${facet}:"${label}"`)
        .join(' OR ')})`;
    })
    .join(` ${operator} `);
}

function toggleSelectedTags(facet: string, tag: any, tagsPlugin: any) {
  const otherTags = tagsPlugin.data.tags.filter((x) => x.facet !== facet);

  const isActive = !!tagsPlugin.data.tags.find(
    (x) => x.facet === facet && x.label === tag.label
  );

  if (isActive) {
    tagsPlugin.data.setTags([...otherTags]);
  } else {
    tagsPlugin.data.setTags([...otherTags, tag]);
  }
}
