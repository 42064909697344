import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useCallback, useRef, useState } from 'react';
import { DropdownContext } from './DropdownContext';

export const DropdownMenu = ({ openButton, closeButton, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef();
  const menuRef = useRef();
  const closeMenu = useCallback(() => setIsOpen(false), []);
  useOnClickOutside([buttonRef, menuRef], closeMenu);

  return (
    <DropdownContext.Provider value={{ isOpen, setIsOpen }}>
      <div
        ref={buttonRef}
        className="cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? closeButton : openButton}
      </div>

      {isOpen && (
        <>
          <div
            ref={menuRef}
            className="absolute top-[86px] lg:top-[68px] right-[8px] w-60 z-50 min-w-min bg-white rounded shadow-gray-400 md:shadow-md"
          >
            <ul className="py-1">{children}</ul>
          </div>
          <div className="h-screen w-screen absolute top-[78px] lg:top-[60px] inset-x-0 bg-black/40"></div>
        </>
      )}
    </DropdownContext.Provider>
  );
};
