import { useEffect } from 'react';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import Head from 'next/head';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import Navigation from '@/components/Navigation/Navigation';
import { AnimatePresence } from 'framer-motion';
import { Provider } from 'react-redux';
import { store } from '../store/store';
import { useRouter } from 'next/router';
import '../styles/globals.css';
import '../styles/algolia.css';
import '@mux/videojs-kit/dist/index.css';
import '../styles/videojs.css';
import 'nprogress/nprogress.css';
import { Toaster, toast } from 'react-hot-toast';
import {
  FB_PIXEL_ID,
  fbTrackPageView,
  GTM_ID,
  gtmTrackPageView,
  mixpanelInit
} from '../helpers';

const TopProgressBar = dynamic(
  () => {
    return import('components/Navigation/NavigationProgressBar');
  },
  { ssr: false }
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      useErrorBoundary: true,
      staleTime: 60 * 1000 // 1 minute
    }
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      toast.error(`Something went wrong: ${error?.message || error}`);
      console.log('error', error);
    }
  })
});

mixpanelInit();

function App({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbTrackPageView();

    const handleRouteChange = () => {
      fbTrackPageView();
      gtmTrackPageView(router.pathname);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Head>

        {/* Google Tag Manager */}
        {GTM_ID && (
          <Script strategy="afterInteractive" id="gtm-load">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');`}
          </Script>
        )}
        {/* Facebook Pixel Code */}
        {FB_PIXEL_ID && (
          <Script strategy="afterInteractive" id="facebook-pixel-load">
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${FB_PIXEL_ID}');`}
          </Script>
        )}
        {/* Disbled Chromecast for now */}
        {/* <Script
          strategy="lazyOnload"
          src="https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"
        /> */}
        {/* Cookiebot */}
        <Script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="55fae9df-1a1d-4afd-8d83-fec27590c968"
          data-blockingmode="auto"
        />
        <TopProgressBar />
        <Navigation></Navigation>
        <AnimatePresence
          exitBeforeEnter
          initial={false}
          onExitComplete={() => window.scrollTo(0, 0)}
        >
          <Component {...pageProps} />
        </AnimatePresence>

        <Toaster
          toastOptions={{
            error: {
              style: {
                borderLeft: '10px solid #f44336',
                padding: 15,
                textAlign: 'left'
              }
            }
          }}
        />
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
