import {
  ACCOUNT_SIGN_IN_URL,
  ALL_RECIPES_URL,
  CONTACT_URL,
  COOKING_SCHOOL_URL,
  EVENTS_URL,
  FAQ_URL,
  PURCHASE_GIFTCARD_URL,
  COOKIES_PERSONAL_DATA_URL,
  REDEEM_GIFTCARD_URL,
  SHOP_URL,
  TALKS_URL
} from '@/constants';
import { mixpanelIdentify } from 'helpers';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { UserState } from 'store/userSlice';
import { DropdownMenu, DropdownMenuItem } from './Dropdown';
import { RecipeIcon, CloseIcon, MenuIcon } from './Icons';
import { MenuButton, MenuLink } from './MenuItem';
import SearchBar from './SearchBar';
import UserMenu from './UserMenu';

export default function Navigation() {
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user.IsLoggedIn) {
      mixpanelIdentify(user.AccountNumber);
    }
  }, [user]);

  return (
    <nav className="bg-primary/95 backdrop-blur-sm sticky top-0 z-50 px-3 md:px-4 py-2.5 print:hidden">
      <div className="flex items-center">
        <Logo user={user} />
        <PrimaryMenu user={user} />
        <SearchBar />
        <div
          className={`
            flex items-center            
            ${user.IsLoggedIn ? 'lg:ml-auto' : 'ml-auto'}
          `}
        >
          <UserMenu />
          <SecondaryMenu user={user} />
        </div>
      </div>
    </nav>
  );
}

const Logo = ({ user }: { user: UserState }) => (
  <div className="w-40">
    <Link href={user.IsLoggedIn ? ALL_RECIPES_URL : '/'}>
      <a className="flex ml-0 mr-4" style={{ height: 40, width: 105 }}>
        <Image alt="Logo" src="/images/logo.svg" height={40} width={105} />
      </a>
    </Link>
  </div>
);

const PrimaryMenu = ({ user }: { user: UserState }) => (
  <div className="ml-auto lg:ml-0">
    <ul className="hidden lg:flex flex-row space-x-3">
      <MenuLink url={ALL_RECIPES_URL}>
        <span>Opskrifter</span>
      </MenuLink>
      <MenuLink url={PURCHASE_GIFTCARD_URL}>
        <span>Gavekort</span>
      </MenuLink>
      <MenuLink url={SHOP_URL} target="_blank">
        <span>Shop</span>
      </MenuLink>
    </ul>

    {user.IsLoggedIn && (
      <div className="lg:hidden mr-4 md:mr-8 lg:mr-4">
        <MenuLink url={ALL_RECIPES_URL} mobileLabel="Alle">
          <RecipeIcon />
        </MenuLink>
      </div>
    )}
  </div>
);

const SecondaryMenu = ({ user }: { user: UserState }) => {
  return (
    <DropdownMenu
      openButton={
        <MenuButton mobileLabel="Menu">
          <MenuIcon />
        </MenuButton>
      }
      closeButton={
        <MenuButton mobileLabel="Luk">
          <CloseIcon />
        </MenuButton>
      }
    >
      <DropdownMenuItem className="font-bold text-lg" url={ALL_RECIPES_URL}>
        Opskrifter
      </DropdownMenuItem>
      <DropdownMenuItem
        className="font-bold text-lg"
        url={PURCHASE_GIFTCARD_URL}
      >
        Gavekort
      </DropdownMenuItem>
      <DropdownMenuItem
        className="font-bold text-lg"
        url={SHOP_URL}
        target="_blank"
      >
        Shop
      </DropdownMenuItem>
      <DropdownMenuItem className="font-bold text-lg" url={EVENTS_URL}>
        Events
      </DropdownMenuItem>
      {user.IsLoggedIn === false && (
        <DropdownMenuItem
          className="font-bold text-lg"
          url={ACCOUNT_SIGN_IN_URL}
        >
          Log ind
        </DropdownMenuItem>
      )}
      <li>
        <hr className="h-1 mt-2 mb-1" />
      </li>
      <DropdownMenuItem url={COOKING_SCHOOL_URL}>Kokkeskole</DropdownMenuItem>
      <DropdownMenuItem url={TALKS_URL}>Foredrag</DropdownMenuItem>
      <DropdownMenuItem url={REDEEM_GIFTCARD_URL}>
        Indløs gavekort
      </DropdownMenuItem>
      <DropdownMenuItem url={FAQ_URL}>Spørgsmål og svar</DropdownMenuItem>
      <DropdownMenuItem url={CONTACT_URL}>Kontakt</DropdownMenuItem>
      <DropdownMenuItem url={COOKIES_PERSONAL_DATA_URL}>
        Cookies og persondata
      </DropdownMenuItem>
    </DropdownMenu>
  );
};
