import { format, parseISO } from 'date-fns';
import { da } from 'date-fns/locale';

export const formatDate = (date: Date | string, hasTime: boolean) =>
  hasTime
    ? format(parseISO(date.toString()), "d. MMMM yyyy 'kl.' HH:mm", {
        locale: da
      })
    : format(parseISO(date.toString()), 'd. MMMM yyyy', {
        locale: da
      });

export const iso8601 = (date: Date) =>
  format(parseISO(date.toString()), "yyyy-MM-dd'T'HH:mm:ss'.000+02:00'");

export const formatCommentDate = (date: Date) =>
  format(parseISO(date.toString()), "yyyy-MM-dd'T'HH:mm:ss'.000+04:00'");
