import { useLayoutEffect, useState } from 'react';

const identifier = 'data-scroll-lock';

export const useLockScroll = () => {
  const [lockScroll, setLockScroll] = useState(false);

  useLayoutEffect(() => {
    if (!lockScroll) {
      return;
    }

    const scrollX = window.pageXOffset;
    const scrollY = window.pageYOffset;

    // RTL <body> scrollbar
    const scrollbarX =
      Math.round(document.documentElement.getBoundingClientRect().left) +
      document.documentElement.scrollLeft;
    const paddingProp = scrollbarX ? 'paddingLeft' : 'paddingRight';

    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;

    const alreadyLocked = document.body.hasAttribute(identifier);

    if (alreadyLocked) {
      return;
    }

    Object.assign(document.body.style, {
      position: 'fixed',
      overflow: 'hidden',
      top: `-${scrollY}px`,
      left: `-${scrollX}px`,
      right: '0',
      [paddingProp]: `${scrollbarWidth}px`
    });

    document.body.setAttribute(identifier, '');

    return () => {
      Object.assign(document.body.style, {
        position: '',
        overflow: '',
        top: '',
        left: '',
        right: '',
        [paddingProp]: ''
      });

      document.body.removeAttribute(identifier);
      window.scrollTo(scrollX, scrollY);
    };
  }, [lockScroll]);

  return {
    lockScroll,
    setLockScroll
  };
};
