import { API_ROOT_URL } from '@/constants';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { toggleFavorite as toggleFavoriteAction } from 'store/userSlice';
import { Recipe, RecipeTeaser } from 'types';

const toggleFavoriteRequest = async (recipeId: number) => {
  const response = await fetch(
    API_ROOT_URL + '/ProfileV2/ToggleFavorite?id=' + recipeId,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }
  );
  if (!response.ok) {
    throw {
      status: response.status,
      message: response.statusText
    };
  }
  return response;
};

export const useFavorites = () => {
  const dispatch = useDispatch();

  return {
    toggleFavorite: (recipe: Recipe) =>
      useMutation(toggleFavoriteRequest, {
        onSuccess: () => {
          const teaser = recipe as RecipeTeaser;
          dispatch(toggleFavoriteAction({ Recipe: teaser }));
        }
      })
  };
};
