import mixpanel from 'mixpanel-browser';
export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

export const mixpanelInit = () => {
  mixpanel.init(MIXPANEL_TOKEN, {
    api_host: 'https://api-eu.mixpanel.com'
  });
};

export const mixpanelTrack = (event: string, data: any) => {
  if (MIXPANEL_TOKEN && typeof window !== 'undefined') {
    mixpanel.track(event, data);
  }
};

export const mixpanelIdentify = (uid: string) => {
  if (MIXPANEL_TOKEN && typeof window !== 'undefined') {
    try {
      mixpanel.identify(uid);
    } catch (error) {
      console.error(error);
    }
  }
};
