import {
  CancelSubscription,
  ChangeSubscription,
  UpdatePassword,
  UpdateProfile
} from 'types';
import { fetcher, postRequest } from './fetch-helper';

export const saveProfile = async (data: UpdateProfile) =>
  fetcher('/ProfileV2/UpdateProfile', postRequest(data));

export const updatePassword = async (data: UpdatePassword) =>
  fetcher('/ProfileV2/UpdatePassword', postRequest(data));

export const cancelSubscription = async (data: CancelSubscription) =>
  fetcher(
    '/ProfileV2/CancelSubscription',
    postRequest({
      SubscriptionId: data.SubscriptionId,
      CancelReasonId: parseInt(data.CancelReasonId)
    })
  );

export const changeSubscription = async (data: ChangeSubscription) =>
  fetcher('/ProfileV2/ChangeSubscription', postRequest({ ...data }));
