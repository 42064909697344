import { API_ROOT_URL } from '@/constants';

export const fetcher = async (url: string, data: any): Promise<any> => {
  const response = await fetch(API_ROOT_URL + url, data);

  try {
    const json = await response.json();
    return response.ok ? Promise.resolve(json) : Promise.reject(json);
  } catch {
    if (response.ok) {
      return Promise.resolve();
    }

    throw new Error(
      `Error! Server returned ${response.status} from url ${url}`
    );
  }
};

export const postRequest = (data: any): RequestInit => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  credentials: 'include',
  body: JSON.stringify(data)
});
